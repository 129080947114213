import React from "react";
import { useHistory, useParams } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

//comps
import HandleFields from "./HandleFields";

//Apollo
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

//query
import {
  REPLY_LIST,
  REPLY_LIST_UPDATE,
  ANSWER_LIST,
} from "../../queries/queries_answer_list";

//Loader
import { CompLoading } from "../Loaders";

export default function EditReplyList() {
  const { id } = useParams();
  const history = useHistory();

  const { loading, data } = useQuery(REPLY_LIST, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  const [setReplyList] = useMutation(REPLY_LIST_UPDATE, {
    refetchQueries: [
      {
        query: ANSWER_LIST,
      },
    ],
  });

  const triggerSave = (variables) => {
    console.log({
      variables: {
        ...variables,
        id,
      },
    });
    setReplyList({
      variables: {
        ...variables,
        id,
      },
    });
    history.push(ROUTES.ANSWER_LIST);
  };

  if (loading) return <CompLoading />;

  return (
    <div className="page-setup">
      <h1>Setup</h1>
      <div className="content-wrapper">
        <p>
          Add, modify or remove answers from this list. Lists with a large
          number of answers (20+) are recommended to maintain variety in the
          automated responses.
        </p>
        <HandleFields
          data={data.getReplyList}
          triggerSave={triggerSave}
          loading={loading}
        />
      </div>
    </div>
  );
}
