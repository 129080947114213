import React from "react";
import ReactDOM from "react-dom";

import Firebase, { FirebaseContext } from "./components/Firebase";

//Endpointgit
import endpoint from "./config/endpoint";

//Apollo
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

//PrimeReact
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

//comps
import App from "./components/App";

//css
import "./global.scss";

const client = new ApolloClient({
  uri: `${endpoint}/graphql`,
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
