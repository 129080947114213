import React, { useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

//Apollo
import { useQuery, useMutation } from "@apollo/react-hooks";

//Context
import { UserContext } from "../App/userContext";

//Routes
import * as ROUTES from "../../constants/routes";

//query
import {
  AUTOMATED_RESPONSE_ACTIVE,
  AUTOMATED_RESPONSE_DEACTIVATE,
  MIGRATION_ACTIVE,
  MIGRATION_DEACTIVATE,
  GET_STATUS,
} from "../../queries/queries_automation";

//Loader
import { CompLoading } from "../Loaders";

//PR
import { InputSwitch } from "primereact/inputswitch";
import { Growl } from "primereact/growl";

//comps
import ResponseRules from "./ResponseRules";

import "./automation.scss";

export default function Automation() {
  const history = useHistory();

  const { role } = useContext(UserContext);
  const { loading, data } = useQuery(GET_STATUS);

  let growl = useRef(null);

  const showEnable = (name) => {
    growl.current.show({
      severity: "success",
      summary: `${name} enabled`,
      detail: "Yours changes have been saved.",
    });
  };
  const showDisable = (name) => {
    const disableMsg = () => {
      const revert = () => {
        switch (name) {
          case "Automated response":
            growl.current.clear();
            return setResponseActive();
          case "Data migration":
            growl.current.clear();
            return setMigrationActive();
          default:
            growl.current.clear();
            return null;
        }
      };
      return (
        <>
          Yours changes have been saved.{" "}
          <span
            onClick={() => revert()}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Click here to revert
          </span>{" "}
          or close ignore this message.
        </>
      );
    };
    growl.current.show({
      life: 7000,
      severity: "warn",
      summary: `${name} disabled`,
      detail: disableMsg(),
    });
  };
  const showAutoSave = () => {
    growl.current.show({
      severity: "success",
      summary: "Configuration Saved",
      detail: "Yours changes have been saved.",
    });
  };

  const [
    setResponseActive,
    { loading: loadingAutoResActive, error: errorAutoResActive },
  ] = useMutation(AUTOMATED_RESPONSE_ACTIVE, {
    refetchQueries: [{ query: GET_STATUS }],
    onCompleted: () => showEnable("Automated response"),
  });

  const [
    setResponseDeactivate,
    { loading: loadingAutoResDeactive, error: errorAutoResDeactive },
  ] = useMutation(AUTOMATED_RESPONSE_DEACTIVATE, {
    refetchQueries: [{ query: GET_STATUS }],
    onCompleted: () => showDisable("Automated response"),
  });

  const [
    setMigrationActive,
    { loading: loadingMigrationActive, error: errorActive },
  ] = useMutation(MIGRATION_ACTIVE, {
    refetchQueries: [{ query: GET_STATUS }],
    onCompleted: () => showEnable("Data migration"),
  });

  const [
    setMigrationDeactivate,
    { loading: loadingMigrationDeactive, error: errorDeactive },
  ] = useMutation(MIGRATION_DEACTIVATE, {
    refetchQueries: [{ query: GET_STATUS }],
    onCompleted: () => showDisable("Data migration"),
  });

  const handleToogle = (trigger, currentValue) => {
    switch (trigger) {
      case "auto_response":
        return currentValue ? setResponseActive() : setResponseDeactivate();
      case "migration":
        return currentValue ? setMigrationActive() : setMigrationDeactivate();
      default:
        return null;
    }
  };

  if (
    errorAutoResActive ||
    errorAutoResDeactive ||
    errorActive ||
    errorDeactive
  ) {
    return <div>Error!!!</div>;
  }

  if (role >= 2) {
    history.push(ROUTES.ROOT);
  }

  if (loading) return <CompLoading />;

  const saving = () => {
    return (
      <div>
        <small>Saving... Please wait</small>
      </div>
    );
  };

  return (
    <div className="page-setup">
      <h1>Setup</h1>
      <Growl ref={growl} position="bottomright" />
      <div className="content-wrapper">
        <h2>Automation</h2>
        <p>
          Choose the level of automation you want for user reviews. This
          includes whether or not the automatic replies should be sent according
          to their schedule and if the daily migration of review data into
          BigQuery is active. Additionally, you can select which answer list
          will be associated with each scenario of user review.
        </p>
        <div className="grid-wrapper">
          <div>
            <h3>Automated response to reviews:</h3>
            <InputSwitch
              checked={data.AutomatedResponseInfo.isActivated}
              onChange={(e) => handleToogle("auto_response", e.value)}
              disabled={loadingAutoResActive || loadingAutoResDeactive}
            />
            {(loadingAutoResActive || loadingAutoResDeactive) && saving()}
          </div>
          <div>
            <h3>Data migration to BigQuery:</h3>
            <InputSwitch
              checked={data.BigQueryMigrationInfo.isActivated}
              onChange={(e) => handleToogle("migration", e.value)}
              disabled={loadingMigrationActive || loadingMigrationDeactive}
            />
            {(loadingMigrationActive || loadingMigrationDeactive) && saving()}
          </div>
        </div>
        <ResponseRules showAutoSave={showAutoSave} />
      </div>
    </div>
  );
}
