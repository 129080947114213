import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

//PrimeReact
import { Menubar } from "primereact/menubar";

//Context
import { FirebaseContext } from "../Firebase";
import { UserContext } from "../App/userContext";

import * as ROUTES from "../../constants/routes";

import MenuAdmin from "./MenuAdmin";
import MenuManager from "./MenuManager";
import MenuEditor from "./MenuEditor";

export default function MainMenu() {
  const firebase = useContext(FirebaseContext);
  const { role } = useContext(UserContext);

  const history = useHistory();

  const handleSignOut = async () => {
    await firebase.SignOut();
    history.push(ROUTES.ROOT);
  };

  const handleMenuPerUserType = () => {
    switch (role) {
      case 0:
        return MenuAdmin();
      case 1:
        return MenuManager();
      case 2:
        return MenuEditor();
      default:
        return [];
    }
  };

  const items = [
    {
      label: "Main",
      icon: "pi pi-fw pi-list",
      command: () => history.push(ROUTES.ROOT),
    },
    {
      label: "Replied",
      icon: "pi pi-fw pi-check-circle",
      command: () => history.push(ROUTES.REPLIED),
    },
    {
      label: "Search",
      icon: "pi pi-fw pi-search-plus",
      command: () => history.push(ROUTES.SEARCH),
    },
    ...handleMenuPerUserType(),
    {
      label: "Logout",
      icon: "pi pi-fw pi-sign-out",
      command: () => handleSignOut(),
    },
  ];

  return <Menubar model={items} />;
}
