import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { FirebaseContext } from '../Firebase';

import { UserContext } from '../App/userContext';

//Comps
import Submit from '../Submit';

//Apollo
import { useQuery } from '@apollo/react-hooks';

//query
import { GET_REPLY_LIST } from '../../queries/queries_reply_lists';

//PrimeReact
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';

import './table.scss';

export default function Dashboard({ history }) {
	const firebase = useContext(FirebaseContext);

	const [ loading, setLoading ] = useState(true);
	const [ tableData, setTableData ] = useState([]);
	const [ selection, setSelection ] = useState([]);

	const [ customBulk, setCustomBulk ] = useState(false);
	const [ customBulkReply, setCustomBulkReply ] = useState('');

	const { role } = useContext(UserContext);

	const ref = firebase.db
		.collection('reviews-epa-7')
		.where('reviewStatus', '==', 'pending')
		.orderBy('createTime', 'desc')
		.limit(500);

	const { data: queryData } = useQuery(GET_REPLY_LIST);

	useEffect(
		() => {
			if (queryData) {
				const observer = ref.onSnapshot(async (snapshot) => {
					const tableFields = snapshot.docs.map((doc) => {
						const {
							reviewId,
							locationId,
							address,
							comment,
							reviewer,
							createTime,
							starRating,
							sentiment,
							storeCode,
							urlReview
						} = doc.data();
						return {
							reviewId,
							locationId,
							reviewer: reviewer.displayName,
							pic: reviewer.profilePhotoUrl && (
								<img src={reviewer.profilePhotoUrl} alt={reviewer.displayName} height="40" width="40" />
							),
							comment,
							createTime: moment(createTime.toDate()).format('YYYY-MM-DD h:mm a'),
							starRating,
							sentiment: sentiment.score,
							storeCode,
							locality: `${address.locality}, ${address.administrativeArea}`,
							location: (
								<a href={urlReview} target="_blank" rel="noopener noreferrer">
									<i className="pi pi-external-link" />
								</a>
							),
							response: 'Default',
							language: sentiment.language
						};
					});
					setTableData(tableFields);
					setLoading(false);
				});
				return () => observer();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[ queryData, ref ]
	);

	if (loading) {
		return (
			<div className="global-loading">
				<ProgressSpinner />
			</div>
		);
	}

	const onEditorValueChangeForRowEditing = (props, newValue) => {
		let updatedData = [ ...props.value ];
		updatedData[props.rowIndex][props.field] = newValue;
		return setTableData(updatedData);
	};

	const editorForRowEditing = (props, field) => {
		const options = () => {
			const { starRating, language } = props.rowData;
			const getCustomList = _.filter(queryData.getReplyLists, {
				starRating,
				language,
				preset: false
			});
			if (_.isEmpty(getCustomList)) {
				const { listMessages } = _.find(queryData.getReplyLists, {
					starRating: props.rowData.starRating
				});
				return listMessages;
			}
			const allCustomReplies = _.reduce(
				getCustomList,
				(acc, { listMessages }) => [ ...acc, ...listMessages ],
				[]
			);
			return allCustomReplies;
		};
		const optionsBuilder = (arr) =>
			_.map(arr, (i) => {
				return {
					label: i,
					value: i
				};
			});
		return (
			<Dropdown
				options={optionsBuilder(options())}
				value={props.rowData[field]}
				editable={true}
				onChange={(e) => onEditorValueChangeForRowEditing(props, e.target.value)}
			/>
		);
	};

	const onRowEditCancel = (event) => {
		const { index } = event;
		let newState = tableData;
		newState[index] = {
			...event.data,
			response: 'Default'
		};
		return setTableData([ ...newState ]);
	};

	return (
		<div className="main-table">
			{role <= 2 && (
				<div className="bulk-reply">
					<div className="toogle-wrapper">
						<InputSwitch checked={customBulk} onChange={(e) => setCustomBulk(e.value)} />
						<label>Enable Custom Bulk Reply</label>
					</div>
					<InputText
						disabled={!customBulk}
						value={customBulkReply}
						onChange={(e) => setCustomBulkReply(e.target.value)}
					/>
					<small>
						Select this option to overwrite <strong>all selected responses</strong> with this text.
					</small>
				</div>
			)}

			{!_.isEmpty(selection) && <Submit data={selection} customBulkReply={customBulk ? customBulkReply : null} />}

			<DataTable
				value={tableData}
				editMode="row"
				selection={selection}
				onSelectionChange={({ value }) => setSelection([ ...value ])}
				onRowEditCancel={onRowEditCancel}
				sortMode="multiple"
				responsive={true}
				reorderableColumns={true}
				paginator={true}
				rows={50}
			>
				{role < 3 && <Column selectionMode="multiple" style={{ width: '45px' }} />}
				<Column field="pic" header="Profile" style={{ width: 100, padding: 5, textAlign: 'center' }} />
				<Column field="reviewer" header="Reviewer" sortable={true} style={{ width: 140, padding: 5 }} />
				<Column
					field="comment"
					header="Comment"
					style={{ width: 300 }}
					filter={true}
					filterMatchMode="contains"
				/>
				<Column field="language" header="Lang" sortable={true} style={{ width: 80, textAlign: 'center' }} />
				<Column field="createTime" header="Creation time" sortable={true} style={{ width: 190 }} />
				<Column field="starRating" header="Rating" sortable={true} style={{ width: 80, textAlign: 'center' }} />
				<Column
					field="sentiment"
					header="Sentiment Analysis"
					sortable={true}
					style={{ width: 120, textAlign: 'center' }}
				/>
				<Column field="storeCode" header="Store" filter={true} style={{ width: 100, padding: 5 }} />
				<Column
					field="locality"
					header="Locality"
					filter={true}
					filterMatchMode="contains"
					style={{ width: 100, padding: 5 }}
				/>
				<Column field="location" header="Location" style={{ width: '80px', textAlign: 'center' }} />
				{role < 3 && (
					<Column
						field="response"
						header="Response"
						style={{ width: 220 }}
						editor={(props) => editorForRowEditing(props, 'response')}
					/>
				)}
				{role < 3 && <Column rowEditor={!customBulk} style={{ width: '70px', textAlign: 'center' }} />}
			</DataTable>
		</div>
	);
}
