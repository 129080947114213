import React, { useRef, useState, useContext } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";

//Context
import { UserContext } from "../App/userContext";

//Routes
import * as ROUTES from "../../constants/routes";

//Apollo
import { useQuery, useMutation } from "@apollo/react-hooks";

//query
import { USERS, UPDATE_USER } from "../../queries/queries_users";

//Loader
import { CompLoading } from "../Loaders";

//Helper
import userRoles from "./userRoles";

//PrimeReact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { InputSwitch } from "primereact/inputswitch";

export default function Users() {
  const history = useHistory();
  const { role } = useContext(UserContext);
  const [users, setUsers] = useState(null);
  let messages = useRef(null);

  const [
    updateUser,
    { loading: loadingMutation, },
  ] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: USERS }],
    onCompleted: ({ userUpdate }) =>
      messages.current.show({
        life: 3000,
        severity: "success",
        detail: userUpdate,
        closable: false,
      }),
  });

  const { loading, data } = useQuery(USERS, {
    onCompleted: () => setUsers(data.users),
  });

  if (role !== 0) {
    history.push(ROUTES.ROOT);
    return null;
  }

  if (loading) return <CompLoading />;

  const handleOnChange = (uid, name, value) => {
    console.log(uid, name, value);
    const currentIndex = _.findIndex(users, ["uid", uid]);
    let newState = [...users];
    newState[currentIndex] = { ...newState[currentIndex], [name]: value };
    return setUsers([...newState]);
  };

  const fieldDisplayName = (rowData) => {
    return (
      <InputText
        value={rowData.displayName}
        disabled={loadingMutation}
        name="displayName"
        onChange={({ target: { name, value } }) =>
          handleOnChange(rowData.uid, name, value)
        }
      />
    );
  };

  const fieldEmail = (rowData) => {
    return (
      <InputText
        value={rowData.email}
        disabled={loadingMutation}
        name="email"
        onChange={({ target: { name, value } }) =>
          handleOnChange(rowData.uid, name, value)
        }
      />
    );
  };

  const fieldRole = (rowData) => {
    return (
      <Dropdown
        options={userRoles}
        value={rowData.role}
        disabled={loadingMutation}
        name="role"
        onChange={({ target: { name, value } }) =>
          handleOnChange(rowData.uid, name, value)
        }
      />
    );
  };

  const fieldDisable = (rowData) => {
    return (
      <InputSwitch
        checked={!rowData.disabled}
        disabled={loadingMutation}
        name="disabled"
        onChange={({ target: { name, value } }) =>
          handleOnChange(rowData.uid, name, !value)
        }
      />
    );
  };

  const handleAction = (rowData) => {
    return (
      <Button
        label="Save"
        disabled={loadingMutation}
        onClick={() => {
          updateUser({
            variables: rowData,
            onCompleted: (rowData) => console.log(rowData, "done"),
          });
        }}
      />
    );
  };

  return (
    <div className="page-setup">
      <h1>Users Manager</h1>
      <div className="content-wrapper">
        <Button
          label="Add user"
          icon="pi pi-user-plus"
          className="p-button-success p-button-lg p-button-raised p-button-rounded"
          onClick={() => history.push(ROUTES.USERS_ADD)}
          style={{ marginBottom: 15 }}
        />
        <Messages ref={messages} />
        <DataTable value={users} responsive={true}>
          <Column
            field="disabled"
            header="Enabled"
            body={fieldDisable}
            style={{ width: 80, textAlign: "center" }}
          />
          <Column field="email" header="Email" body={fieldEmail} />
          <Column field="displayName" header="Name" body={fieldDisplayName} />
          <Column
            field="role"
            header="Role"
            body={fieldRole}
            style={{ width: 280, textAlign: "center" }}
          />
          <Column
            header="Action"
            body={handleAction}
            style={{ width: 80, textAlign: "center" }}
          />
        </DataTable>
      </div>
    </div>
  );
}
