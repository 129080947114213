import React, { useState } from "react";
import _ from "lodash";

import { useQuery } from "@apollo/react-hooks";

//PR
import { Calendar } from "primereact/calendar";
import { Rating } from "primereact/rating";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

//query
import { SEARCH_CRITERIA } from "../../queries/queries_search";

//Loader
import { CompLoading } from "../Loaders";

export default function Filters({ handleSearch, parentStatus }) {
  const [criteria, setCriteria] = useState(null);

  const [rating, setRating] = useState(null);
  const [date, setDate] = useState(null);
  const [usState, setUsState] = useState(null);
  const [status, setStatus] = useState("pending");
  const [storeCode, setStoreCode] = useState(null);
  const [rows, setRows] = useState(100);

  const { loading } = useQuery(SEARCH_CRITERIA, {
    onCompleted: ({ getSearchCriteria }) => setCriteria(getSearchCriteria[0]),
  });

  const handleClearSearch = () => {
    setRating(null);
    setDate(null);
    setUsState(null);
    setStatus("pending");
    setStoreCode(null);
    setRows(100);
  };

  function handleRowsSelector(rows) {
    return _.map(rows, (row) => {
      return {
        label: row,
        value: row,
      };
    });
  }

  function searchFieldsBuilder() {
    const fieldsObj = {
      administrativeArea: usState,
      starRating: rating,
      reviewStatus: status,
      storeCode,
      startDate: date ? date[0] : null,
      endDate: date ? date[1] : null,
    };
    //remove null fields
    return _.pickBy(fieldsObj, _.identity);
  }

  if (loading || !criteria) return <CompLoading />;

  const handleEnableSubmit = () => {
    if (!date) return false;
    if (date[1]) return true;
  };

  return (
    <div className="filters-wrapper">
      <div className="fields">
        <div className="search-field">
          <label>Range:</label>
          <Calendar
            minDate={new Date(criteria.dates[0])}
            maxDate={new Date(criteria.dates[1])}
            value={date}
            selectionMode="range"
            readOnlyInput={true}
            onChange={({ value }) => setDate(value)}
          />
          <small>A Range must be selected.</small>
        </div>
        <div className="search-field">
          <label>Rating:</label>
          <Rating
            value={rating}
            onChange={({ value }) => setRating(value)}
            stars={criteria.ratings.lenght}
          />
        </div>
        <div className="search-field">
          <label>State:</label>
          <Dropdown
            value={usState}
            options={criteria.states}
            onChange={({ value }) => setUsState(value)}
            placeholder="Select a State"
          />
        </div>
        <div className="search-field">
          <label>Store Code:</label>
          <InputText
            value={storeCode}
            onChange={({ target: { value } }) => setStoreCode(value)}
          />
        </div>
        <div className="search-field">
          <label>Status:</label>
          <SelectButton
            value={status}
            options={criteria.status}
            onChange={({ value }) => setStatus(value)}
          ></SelectButton>
        </div>
        <div className="search-field">
          <label>Rows:</label>
          <Dropdown
            value={rows}
            options={handleRowsSelector(criteria.rows)}
            onChange={({ value }) => setRows(value)}
            placeholder="Total results"
          />
        </div>
      </div>
      <div className="actions">
        <Button
          label="Search"
          icon="pi pi-search"
          iconPos="right"
          disabled={!handleEnableSubmit()}
          onClick={() => {
            parentStatus(status);
            handleSearch(searchFieldsBuilder());
          }}
        />
        <Button label="Clear filters" onClick={() => handleClearSearch()} />
      </div>
    </div>
  );
}
