const config = {
  apiKey: "AIzaSyBsFy-__eb0-fLRlYKzK5PXZ8i6D63lVF8",
  authDomain: "sol-naciente-gmb-automation.firebaseapp.com",
  projectId: "sol-naciente-gmb-automation",
  storageBucket: "sol-naciente-gmb-automation.appspot.com",
  messagingSenderId: "623089275878",
  appId: "1:623089275878:web:c22c4379c4aedc6bedbc27",
};

export default config;
