import config from "../../config/firebase";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
  }
  // *** Auth ***
  SignIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  SignOut = () => this.auth.signOut();
}

export default Firebase;
