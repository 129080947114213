import React from "react";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

//Apollo
import { useMutation } from "@apollo/react-hooks";

//query
import { ANSWER_LIST, ADD_LIST } from "../../queries/queries_answer_list";

//comps
import HandleFields from "./HandleFields";

export default function AddReplyList() {
  const history = useHistory();

  const [setReplyList, { loading }] = useMutation(ADD_LIST, {
    refetchQueries: [
      {
        query: ANSWER_LIST,
      },
    ],
  });

  const triggerSave = async (variables) => {
    if (!variables) return null;
    await setReplyList({
      variables,
    });
    history.push(ROUTES.ANSWER_LIST);
  };

  return (
    <div className="page-setup">
      <h1>Add Reply List</h1>
      <div className="content-wrapper">
        <p>
          Create a new answer list for a specific purpose. Please choose a
          unique name and add a short description of the list purpose. Lists
          with a large number of answers (20+) are recommended to maintain
          variety in the automated responses.
        </p>
        <HandleFields triggerSave={triggerSave} loading={loading} />
      </div>
    </div>
  );
}
