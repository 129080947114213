export const ROOT = "/";
export const SEARCH = "/search";
export const USERS = "/users";
export const USERS_ADD = "/users/add";
export const REPLIED = "/replied";
export const AUTOMATION = "/automation";
export const ANSWER_LIST = "/answer-list";
export const EDIT_REPLIES = "/answer-list/replies/edit";
export const ADD_REPLIES = "/answer-list/replies/add";
export const RESPONSE_SCHEDULING = "/response-scheduling";
