import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//Context
import { UserContext } from "../App/userContext";

//Routes
import * as ROUTES from "../../constants/routes";

//Apollo
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

//query
import {
  MONK,
  SET_RESPOSE_SCHEDULING,
} from "../../queries/queries_response_scheduling";

//PR
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

//Loader
import { CompLoading } from "../Loaders";

//assets
import hoursOptions from "../../assets/hours.json";
import timezoneOptions from "../../assets/timezone.json";

export default function ResponseScheduling() {
  const history = useHistory();
  const { role } = useContext(UserContext);

  const [schedule, setSchedule] = useState([]);
  const [timezone, setTimezone] = useState(null);

  const { loading } = useQuery(MONK);

  const [
    setResponseScheduling,
    { loading: mutationLoading },
  ] = useMutation(SET_RESPOSE_SCHEDULING);

  if (role >= 2) {
    history.push(ROUTES.ROOT);
  }

  if (loading) return <CompLoading />;

  const handleTimezoneOptions = () => {
    return _.map(timezoneOptions, ({ value, text }) => {
      return {
        label: text,
        value,
      };
    });
  };

  const handleSetResponseScheduling = async () => {
    await setResponseScheduling({
      variables: {
        schedule,
        timezone,
      },
    });
  };

  return (
    <div className="page-setup">
      <h1>Response Scheduling</h1>
      <div className="content-wrapper">
        <p>
          Select the hours of the day in which the auto-replies will be sent to
          users. You can select all options if you need a 24/7 auto-reply
          service or select the opening hours of your business to make the
          responses seem more natural.
        </p>
        <h3>Automated response to reviews</h3>
        <div>
          <p>Select the hours on which on to trigger Responses.</p>
          <MultiSelect
            value={schedule}
            options={hoursOptions}
            onChange={(e) => setSchedule(e.value)}
            style={{ minWidth: "15em" }}
            filter={true}
            filterPlaceholder="Search"
            placeholder="Choose"
          />
          <p>You can multi-select options.</p>
        </div>
        <div>
          <p>Selection the hours on which on to trigger Responses.</p>
          <Dropdown
            value={timezone}
            options={handleTimezoneOptions()}
            onChange={({ value }) => setTimezone(value)}
            placeholder="Select a Timezone"
          />
        </div>
        <br />
        <Button
          label="Save"
          disabled={mutationLoading}
          onClick={handleSetResponseScheduling}
        />
      </div>
    </div>
  );
}
