import React, { useState, useContext } from "react";
import { FirebaseContext } from "../Firebase";

import * as ROUTES from "../../constants/routes";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import "./login.scss";

export default function Login({ history }) {
  const firebase = useContext(FirebaseContext);

  const [err, setErr] = useState(null);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await firebase.auth.signInWithEmailAndPassword(user, password);
      history.push(ROUTES.ROOT);
    } catch (error) {
      setErr(error.message);
    }
  };

  return (
    <div className="login">
      <h1>Login</h1>
      {err && <div className="err">{err}</div>}
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            onChange={({ target: { value } }) => setUser(value)}
          />
        </div>
        <div className="input-field">
          <label htmlFor="pass">Password</label>
          <InputText
            id="pass"
            type="password"
            onChange={({ target: { value } }) => setPassword(value)}
          />
        </div>
        <Button label="Login" type="submit" />
      </form>
      <small>Extendo Company</small>
    </div>
  );
}
