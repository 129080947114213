import React from "react";

import logo from "../../images/logo-extendo-web.png";
import MainMenu from "../MainMenu";

export default function Header() {
  return (
    <header>
      <img src={logo} alt="Logo" style={{ padding: 15 }} />
      <MainMenu />
    </header>
  );
}
