import React from "react";
import _ from "lodash";

//Apollo
import { useQuery, useMutation } from "@apollo/react-hooks";

//query
import { RULES, SET_RULES } from "../../queries/queries_automation";

//Loader
import { CompLoading } from "../Loaders";

import { Dropdown } from "primereact/dropdown";

export default function ResponseRules({ showAutoSave }) {
  const { loading, data } = useQuery(RULES);

  const [
    setRules,
    { loading: loadingSetRules },
  ] = useMutation(SET_RULES, {
    onCompleted: () => showAutoSave(),
  });

  const handleChange = async (listId, id) => {
    await setRules({
      variables: {
        id,
        listId,
      },
      refetchQueries: [{ query: RULES }],
    });
  };

  const handleRules = () => {
    const options = _.map(data.getReplyLists, ({ listName, id }) => {
      return {
        label: listName,
        value: id,
      };
    });
    return _.map(data.getRules, ({ label, listId, id }) => {
      return (
        <div key={id} className="item-response-rule">
          <label>{label}:</label>
          <Dropdown
            value={listId}
            options={options}
            onChange={({ value }) => handleChange(value, id)}
            disabled={loadingSetRules}
          />
        </div>
      );
    });
  };

  if (loading) return <CompLoading />;

  return (
    <div className="block-automated-rules">
      <h2>Automated Response Rules</h2>
      {handleRules()}
    </div>
  );
}
