import _ from "lodash";
import React, { useState } from "react";
import axios from "axios";

import endpoint from "../../config/endpoint";

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";

export default function Submit({ data, customBulkReply }) {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const flashResponse = (res) => {
    setResponse(res);
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };

  const handleSubmit = () => {
    const dataToProcess = _.map(
      data,
      ({ reviewId, locationId, response, starRating }) => {
        const handleDefault = () => {
          if (customBulkReply) return customBulkReply;
          return response;
        };
        return {
          reviewId,
          locationId,
          starRating,
          response: handleDefault(),
        };
      }
    );
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(`${endpoint}/batchReply`, dataToProcess);
        setConfirm(false);
        setLoading(false);
        flashResponse(res.data.status);
      } catch (error) {
        throw error;
      }
    };
    console.log("Data From React:", dataToProcess);
    fetchData();
  };

  if (loading) {
    return (
      <ProgressSpinner
        style={{ width: "50px", height: "50px", margin: "10px 0 10px 0" }}
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration=".5s"
      />
    );
  }

  if (response) {
    return (
      <div className="response">
        <Message severity="success" text={response} />
      </div>
    );
  }

  return (
    <div className="actions" style={{ marginBottom: 15 }}>
      <Button
        label="Proceed"
        onClick={() => setConfirm(true)}
        className="p-button-raised p-button-rounded"
      />
      {confirm && (
        <div className="proceed-options">
          <h4>Ready to process? Total: {data.length} comments</h4>
          <Button
            onClick={handleSubmit}
            label="Confirm"
            className="p-button-rounded p-button-success"
          />
          <Button
            onClick={() => setConfirm(false)}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
      )}
    </div>
  );
}
