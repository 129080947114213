import React, { useState } from "react";
import _ from "lodash";

import * as ROUTES from "../../constants/routes";
import { useHistory } from "react-router-dom";

//PR
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import "./answer-list.scss";

export default function HandleFields({ data = {}, loading, triggerSave }) {
  const [listName, setListName] = useState(data.listName || null);
  const [description, setDescription] = useState(data.description || null);

  const [starRating, setStarRating] = useState(data.starRating || null);
  const [language, setLanguage] = useState(data.language || "en");

  const [replies, setReplies] = useState(data.listMessages || [""]);
  const [emptyFields, setEmptyFields] = useState(false);

  const history = useHistory();

  const handleAdd = () => {
    let currentState = replies;
    currentState.push("");
    setReplies([...currentState]);
  };

  const handleDelete = (i) => {
    let currentState = replies;
    _.pullAt(currentState, i);
    setReplies([...currentState]);
  };

  const handleChange = (value, i) => {
    let currentState = replies;
    currentState[i] = value;
    setReplies([...currentState]);
  };

  const handleReplies = () => {
    return _.map(replies, (reply, i) => {
      return (
        <div key={i}>
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-secondary"
            onClick={() => handleDelete(i)}
          />
          <InputText
            placeholder="Enter a Reply"
            value={replies[i]}
            onChange={({ target: { value } }) => handleChange(value, i)}
          />
        </div>
      );
    });
  };

  const handleSave = async () => {
    if (_.isEmpty(listName) || _.isEmpty(description)) {
      return setEmptyFields(true);
    }
    triggerSave({
      listName,
      description,
      language,
      starRating,
      listMessages: _.compact(replies),
    });
  };

  const langOptions = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Spanish",
      value: "es",
    },
  ];

  const rateOptions = [
    {
      label: "1 Star",
      value: 1,
    },
    {
      label: "2 Stars",
      value: 2,
    },
    {
      label: "3 Stars",
      value: 3,
    },
    {
      label: "4 Stars",
      value: 4,
    },
    {
      label: "5 Stars",
      value: 5,
    },
  ];

  return (
    <div className="answer-list-fields">
      <div className="block-fields">
        <div>
          <label>Title:</label>
          <InputText
            placeholder="Enter list title."
            value={listName}
            className={emptyFields && _.isEmpty(listName) ? "p-error" : ""}
            style={{ marginRight: ".25em" }}
            onChange={({ target: { value } }) => setListName(value)}
          />
          {emptyFields && _.isEmpty(listName) && (
            <Message severity="error" text="Title is required" />
          )}
        </div>
        <div>
          <label>Description:</label>
          <InputTextarea
            placeholder="Enter a short description for this list."
            value={description}
            className={emptyFields && _.isEmpty(description) ? "p-error" : ""}
            onChange={({ target: { value } }) => setDescription(value)}
          />
          {emptyFields && _.isEmpty(description) && (
            <Message severity="error" text="Description is required" />
          )}
        </div>
        <div>
          <label>Star Rating:</label>
          <Dropdown
            value={starRating}
            options={rateOptions}
            onChange={({ value }) => setStarRating(value)}
            placeholder="Choose a Star Rate"
          />
        </div>
        <div>
          <label>Language:</label>
          <Dropdown
            value={language}
            options={langOptions}
            onChange={({ value }) => setLanguage(value)}
            placeholder="Set Language"
          />
        </div>
      </div>

      <div className="block-reply-list">
        <h3>Reply List</h3>
        <div className="list">{handleReplies()}</div>
        <div className="add">
          <Button
            icon="pi pi-plus"
            className="p-button-raised p-button-rounded p-button-raised"
            onClick={() => handleAdd()}
            disabled={loading}
          />
        </div>
      </div>

      <div className="block-actions">
        <Button
          label="Cancel"
          className="p-button-secondary"
          onClick={() => history.push(ROUTES.ANSWER_LIST)}
          disabled={loading}
        />
        <Button label="Save" onClick={() => handleSave()} disabled={loading} />
      </div>
    </div>
  );
}
