import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function OptionsUsers() {
  const history = useHistory();
  return [
    {
      label: "Users",
      icon: "pi pi-fw pi-user",
      items: [
        {
          label: "List",
          icon: "pi pi-fw pi-users",
          command: () => history.push(ROUTES.USERS),
        },
        {
          label: "New User",
          icon: "pi pi-fw pi-user-plus",
          command: () => history.push(ROUTES.USERS_ADD),
        },
      ],
    },
  ];
}
