import React from "react";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import _ from "lodash";

//Apollo
import { useQuery, useMutation } from "@apollo/react-hooks";

//query
import { ANSWER_LIST, DELETE_LIST } from "../../queries/queries_answer_list";

//PR
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//Loader
import { CompLoading } from "../Loaders";

export default function AnswerList() {
  const { loading, data } = useQuery(ANSWER_LIST);

  const [deleteList] = useMutation(DELETE_LIST, {
    refetchQueries: [{ query: ANSWER_LIST }],
  });

  const history = useHistory();

  if (loading) return <CompLoading />;

  const handleTableData = () => {
    return _.map(
      data.getReplyLists,
      ({ preset, listName, description, id }) => {
        return {
          listName,
          description,
          edit: (
            <i
              className="pi pi-pencil"
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`${ROUTES.EDIT_REPLIES}/${id}`)}
            />
          ),
          delete: !preset && (
            <i
              className="pi pi-trash p-button-secondary"
              style={{ cursor: "pointer" }}
              onClick={() =>
                deleteList({
                  variables: {
                    id,
                  },
                })
              }
            />
          ),
        };
      }
    );
  };

  return (
    <div className="page-setup">
      <h1>Setup</h1>
      <div className="content-wrapper">
        <p>
          The lists will help you establish a number of approved answers for
          specific scenarios that the automated reply process will use. You can
          create lists with answers for each rating (1 to 5 stars) and in
          different languages if necessary. Answers will be chosen at random
          from each list when an automated reply is sent.
        </p>
        <div style={{ marginBottom: 15 }}>
          <Button
            label="Add"
            icon="pi pi-plus"
            iconPos="right"
            onClick={() => history.push(ROUTES.ADD_REPLIES)}
          />
        </div>
        <DataTable value={handleTableData()}>
          <Column field="listName" header="Type" />
          <Column field="description" header="Description" />
          <Column field="edit" header="Edit" />
          <Column field="delete" header="Delete" />
        </DataTable>
      </div>
    </div>
  );
}
