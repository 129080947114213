import { gql } from 'apollo-boost';

const GET_USER = gql`
	query GetUser($uid: ID!) {
		user(uid: $uid) {
			uid
			displayName
			email
			role
			disabled
		}
	}
`;

const USERS = gql`
	query Users {
		users {
			uid
			displayName
			email
			role
			disabled
		}
	}
`;

const ADD_USER = gql`
	mutation AddUser($email: String!, $password: String!, $displayName: String!, $role: Int!) {
		userCreate(email: $email, password: $password, displayName: $displayName, role: $role)
	}
`;

const UPDATE_USER = gql`
	mutation UpdateUser(
		$uid: ID!
		$email: String
		$password: String
		$displayName: String
		$role: Int!
		$disabled: Boolean!
	) {
		userUpdate(
			uid: $uid
			email: $email
			password: $password
			displayName: $displayName
			role: $role
			disabled: $disabled
		)
	}
`;

export { USERS, ADD_USER, GET_USER, UPDATE_USER };
