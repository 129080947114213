import React, { useState, useContext } from "react";
import _ from "lodash";

//Context
import { UserContext } from "../App/userContext";

//Apollo
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

//query
import { GET_REVIEWS } from "../../queries/queries_search";

//query
import { GET_REPLY_LIST } from "../../queries/queries_reply_lists";

//PR
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";

//Loader
import { CompLoading } from "../Loaders";

//Comps
import Filters from "./Filters";
import Submit from "../Submit";

import "./search.scss";
import "../Table/table.scss";

export default function Search() {
  const [status, setStatus] = useState("pending");

  const [customBulk, setCustomBulk] = useState(false);
  const [customBulkReply, setCustomBulkReply] = useState("");

  const [selection, setSelection] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [getData, { loading, data }] = useLazyQuery(GET_REVIEWS, {
    onCompleted: () => {
      const defaultResponse = _.map(data.getReviews, (review) => {
        return {
          ...review,
          reviewId: review.id,
          response: "Default",
        };
      });
      setTableData(defaultResponse);
    },
  });

  const { role } = useContext(UserContext);

  const { loading: queryLoading, data: queryData } = useQuery(GET_REPLY_LIST);

  function handleSearch(fields) {
    getData({ variables: fields });
  }

  const onEditorValueChangeForRowEditing = (props, newValue) => {
    let updatedData = [...props.value];
    updatedData[props.rowIndex][props.field] = newValue;
    return setTableData(updatedData);
  };
  const onRowEditCancel = (event) => {
    const { index } = event;
    let newState = tableData;
    newState[index] = {
      ...event.data,
      response: "Default",
    };
    return setTableData([...newState]);
  };

  const editorForRowEditing = (props, field) => {
    const options = () => {
      const { starRating, language } = props.rowData;
      const getCustomList = _.filter(queryData.getReplyLists, {
        starRating,
        language,
        preset: false,
      });
      if (_.isEmpty(getCustomList)) {
        const { listMessages } = _.find(queryData.getReplyLists, {
          starRating: props.rowData.starRating,
        });
        return listMessages;
      }
      const allCustomReplies = _.reduce(
        getCustomList,
        (acc, { listMessages }) => [...acc, ...listMessages],
        []
      );
      return allCustomReplies;
    };
    const optionsBuilder = (arr) =>
      _.map(arr, (i) => {
        return {
          label: i,
          value: i,
        };
      });
    return (
      <Dropdown
        options={optionsBuilder(options())}
        value={props.rowData[field]}
        editable={true}
        onChange={(e) =>
          onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  };

  const handleMainTable = () => {
    if (loading || queryLoading) return <CompLoading />;
    if (data && data.getReviews.length === 0) {
      return <div className="no-results">No matches found.</div>;
    }
    if (tableData.length === 0) return null;
    return (
      <div className="main-table">
        {role <= 2 && (
          <div className="bulk-reply">
            <div className="toogle-wrapper">
              <InputSwitch
                checked={customBulk}
                onChange={(e) => setCustomBulk(e.value)}
              />
              <label>Enable Custom Bulk Reply</label>
            </div>
            <InputText
              disabled={!customBulk}
              value={customBulkReply}
              onChange={(e) => setCustomBulkReply(e.target.value)}
            />
            <small>
              Select this option to overwrite{" "}
              <strong>all selected responses</strong> with this text.
            </small>
          </div>
        )}

        <DataTable
          value={tableData}
          editMode="row"
          selection={selection}
          onSelectionChange={({ value }) => setSelection([...value])}
          onRowEditCancel={onRowEditCancel}
          sortMode="multiple"
          responsive={true}
          reorderableColumns={true}
          paginator={true}
          rows={50}
        >
          {status === "pending" && role < 3 && (
            <Column selectionMode="multiple" style={{ width: "45px" }} />
          )}

          <Column
            field="comment"
            header="Comment"
            style={{ width: 300 }}
            filter={true}
            filterMatchMode="contains"
          />
          <Column
            field="locality"
            header="Locality"
            filter={true}
            filterMatchMode="contains"
          />
          <Column
            field="starRating"
            header="Rating"
            sortable={true}
            style={{ width: 80, textAlign: "center" }}
          />
          <Column
            field="storeCode"
            header="Store"
            filter={true}
            filterMatchMode="contains"
          />
          <Column
            field="sentiment"
            header="Sentiment Analysis"
            sortable={true}
            style={{ width: 120, textAlign: "center" }}
          />
          <Column field="reviewStatus" header="Review Status" sortable={true} />
          <Column
            field="date"
            header="Date"
            sortable={true}
            style={{ width: 190 }}
          />
          {status === "pending" && role < 3 && (
            <Column
              field="response"
              header="Response"
              style={{ width: 220 }}
              editor={(props) => editorForRowEditing(props, "response")}
            />
          )}
          {status === "pending" && role < 3 && (
            <Column
              rowEditor={!customBulk}
              filterFunction={(e) => console.log(e)}
              style={{ width: "70px", textAlign: "center" }}
            />
          )}
        </DataTable>
      </div>
    );
  };

  return (
    <div className="page-search">
      <h1>Search</h1>
      <div className="content-wrapper">
        <Filters handleSearch={handleSearch} parentStatus={setStatus} />
        {!_.isEmpty(selection) && (
          <Submit
            data={selection}
            customBulkReply={customBulk ? customBulkReply : null}
          />
        )}
        {handleMainTable()}
      </div>
    </div>
  );
}
