import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//Context
import { UserContext } from "../App/userContext";

//Routes
import * as ROUTES from "../../constants/routes";

//Apollo
import { useMutation } from "@apollo/react-hooks";

//query
import { USERS, ADD_USER } from "../../queries/queries_users";

//Loader
import { CompLoading } from "../Loaders";

//Helper
import userRoles from "./userRoles";

//PrimeReact
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import "primeflex/primeflex.css";
import "./users.scss";

export default function AddUser() {
  const history = useHistory();
  const { role: authRole } = useContext(UserContext);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [role, setRole] = useState(null);

  const [addUser, { loading }] = useMutation(ADD_USER, {
    refetchQueries: [{ query: USERS }],
    onCompleted: () => history.push(ROUTES.USERS),
  });

  const handleSubmit = () => {
    addUser({
      variables: {
        email,
        password,
        displayName,
        role,
      },
    });
  };

  if (authRole !== 0) {
    history.push(ROUTES.ROOT);
    return null;
  }

  if (loading) return <CompLoading />;

  return (
    <div className="page-setup add-user">
      <h1>New User</h1>
      <div className="content-wrapper">
        <div className="p-field p-grid">
          <label
            htmlFor="displayName"
            className="p-col-fixed"
            style={{ width: "100px" }}
          >
            Name
          </label>
          <InputText
            name="displayName"
            value={displayName}
            onChange={({ target: { value } }) => setDisplayName(value)}
          />
        </div>
        <div className="p-field p-grid">
          <label
            htmlFor="email"
            className="p-col-fixed"
            style={{ width: "100px" }}
          >
            Email
          </label>
          <InputText
            name="email"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </div>
        <div className="p-field p-grid">
          <label
            htmlFor="password"
            className="p-col-fixed"
            style={{ width: "100px" }}
          >
            Password
          </label>
          <Password
            name="password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
        </div>
        <div className="p-field p-grid">
          <label
            htmlFor="role"
            className="p-col-fixed"
            style={{ width: "100px" }}
          >
            Role
          </label>
          <Dropdown
            name="role"
            options={userRoles}
            value={role}
            disabled={loading}
            onChange={({ target: { value } }) => setRole(value)}
          />
        </div>
        <div className="actions">
          <Button
            label="Save"
            onClick={() => handleSubmit()}
            className="p-button-success"
          />
          <Button label="Cancel" onClick={() => history.push(ROUTES.USERS)} />
        </div>
      </div>
    </div>
  );
}
