import { gql } from "apollo-boost";

const MONK = gql`
  query AutomatedResponseInfo {
    AutomatedResponseInfo {
      id
    }
  }
`;

const SET_RESPOSE_SCHEDULING = gql`
  mutation AutomatedResponseSave($schedule: [Int], $timezone: String!) {
    AutomatedResponseSave(schedule: $schedule, timezone: $timezone) {
      id
    }
  }
`;

export { SET_RESPOSE_SCHEDULING, MONK };
