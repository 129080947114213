import { gql } from "apollo-boost";

const GET_STATUS = gql`
  query GetStatus {
    AutomatedResponseInfo {
      isActivated
    }
    BigQueryMigrationInfo {
      isActivated
    }
  }
`;

const RULES = gql`
  query Rules {
    getRules {
      id
      label
      listId
    }
    getReplyLists {
      listName
      id
    }
  }
`;

const SET_RULES = gql`
  mutation ruleSetList($id: ID!, $listId: ID!) {
    ruleSetList(id: $id, listId: $listId)
  }
`;

const AUTOMATED_RESPONSE_ACTIVE = gql`
  mutation AutomatedResponseActivate {
    AutomatedResponseActivate
  }
`;

const AUTOMATED_RESPONSE_DEACTIVATE = gql`
  mutation AutomatedResponseDeactivate {
    AutomatedResponseDeactivate
  }
`;

const MIGRATION_ACTIVE = gql`
  mutation BigQueryMigrationActivate {
    BigQueryMigrationActivate
  }
`;

const MIGRATION_DEACTIVATE = gql`
  mutation BigQueryMigrationDeactivate {
    BigQueryMigrationDeactivate
  }
`;

export {
  SET_RULES,
  RULES,
  GET_STATUS,
  AUTOMATED_RESPONSE_ACTIVE,
  AUTOMATED_RESPONSE_DEACTIVATE,
  MIGRATION_ACTIVE,
  MIGRATION_DEACTIVATE,
};
