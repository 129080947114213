import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import _ from "lodash";

//Context
import { UserContext } from "../App/userContext";
import { FirebaseContext } from "../Firebase";

//Apollo
import { useQuery } from "@apollo/react-hooks";

//query
import { GET_USER } from "../../queries/queries_users";

import * as ROUTES from "../../constants/routes";

import Login from "../Login";
import { Header, Footer } from "../Theme";
import { Main, Replied } from "../Table";

import Users from "../Users";
import UsersAdd from "../Users/AddUser";
import Automation from "../Automation";
import AnswerList from "../AnswerList";
import EditReplyList from "../AnswerList/EditReplyList";
import AddReplyList from "../AnswerList/AddReplyList";
import ResponseScheduling from "../ResponseScheduling";
import Search from "../Search";

import "./app.scss";

function NoUser() {
  return (
    <Router>
      <Route component={Login} />
    </Router>
  );
}

function HandleIsAuth({ uid }) {
  const { loading, data } = useQuery(GET_USER, {
    variables: { uid },
  });

  if (loading) return "loading...";

  return (
    <div className="main-container">
      <UserContext.Provider value={data.user}>
        <Header />
        <Route exact path={ROUTES.ROOT} component={Main} />
        <Route exact path={ROUTES.USERS} component={Users} />
        <Route exact path={ROUTES.SEARCH} component={Search} />
        <Route exact path={ROUTES.USERS_ADD} component={UsersAdd} />
        <Route exact path={ROUTES.REPLIED} component={Replied} />
        <Route exact path={ROUTES.AUTOMATION} component={Automation} />
        <Route exact path={ROUTES.ANSWER_LIST} component={AnswerList} />
        <Route
          exact
          path={`${ROUTES.EDIT_REPLIES}/:id`}
          component={EditReplyList}
        />
        <Route exact path={ROUTES.ADD_REPLIES} component={AddReplyList} />
        <Route
          exact
          path={ROUTES.RESPONSE_SCHEDULING}
          component={ResponseScheduling}
        />
        <Footer />
      </UserContext.Provider>
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const firebase = useContext(FirebaseContext);

  firebase.auth.onAuthStateChanged((user) =>
    user ? setUser(user) : setUser(null)
  );

  if (_.isEmpty(user)) return <NoUser />;

  return (
    <Router>
      <HandleIsAuth uid={user.uid} />
    </Router>
  );
}

export default App;
