import { gql } from "apollo-boost";

const ANSWER_LIST = gql`
  query getReplyLists {
    getReplyLists {
      preset
      id
      description
      listName
    }
  }
`;

const ADD_LIST = gql`
  mutation listCreate(
    $listName: String!
    $language: String!
    $starRating: Int!
    $description: String!
    $listMessages: [String]
  ) {
    listCreate(
      listName: $listName
      language: $language
      starRating: $starRating
      description: $description
      listMessages: $listMessages
    )
  }
`;

const DELETE_LIST = gql`
  mutation listDelete($id: ID!) {
    listDelete(id: $id)
  }
`;

const REPLY_LIST = gql`
  query GetReplies($id: ID!) {
    getReplyList(id: $id) {
      preset
      listName
      description
      listMessages
      language
      starRating
    }
  }
`;

const REPLY_LIST_UPDATE = gql`
  mutation UpdateReplyList(
    $id: ID!
    $listName: String!
    $language: String!
    $starRating: Int!
    $description: String!
    $listMessages: [String]
  ) {
    listUpdate(
      id: $id
      listName: $listName
      language: $language
      starRating: $starRating
      description: $description
      listMessages: $listMessages
    )
  }
`;

export { ANSWER_LIST, ADD_LIST, DELETE_LIST, REPLY_LIST, REPLY_LIST_UPDATE };
