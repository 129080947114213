import { gql } from "apollo-boost";

const SEARCH_CRITERIA = gql`
  query GetSearchCriteria {
    getSearchCriteria {
      ratings
      states {
        label
        value
      }
      dates
      rows
      status {
        label
        value
      }
      sentiment
    }
  }
`;

const GET_REVIEWS = gql`
  query GetReviews(
    $id: String
    $administrativeArea: String
    $starRating: Int
    $storeCode: String
    $reviewStatus: String
    $startDate: String!
    $endDate: String!
  ) {
    getReviews(
      id: $id
      administrativeArea: $administrativeArea
      starRating: $starRating
      storeCode: $storeCode
      reviewStatus: $reviewStatus
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      comment
      locality
      starRating
      storeCode
      sentiment
      reviewStatus
      locationId
      date
    }
  }
`;

export { GET_REVIEWS, SEARCH_CRITERIA };
