import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function OptionsConfig() {
  const history = useHistory();
  return [
    {
      label: "Config",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Automation",
          icon: "pi pi-fw pi-spinner",
          command: () => history.push(ROUTES.AUTOMATION),
        },
        {
          label: "Scheduling",
          icon: "pi pi-fw pi-calendar",
          command: () => history.push(ROUTES.RESPONSE_SCHEDULING),
        },
      ],
    },
  ];
}
