import _ from 'lodash';
import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../Firebase';

import moment from 'moment';

//Comps
import Submit from '../Submit';

//PrimeReact
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';

export default function Dashboard({ history }) {
	const firebase = useContext(FirebaseContext);

	const [ loading, setLoading ] = useState(true);
	const [ tableData, setTableData ] = useState([]);
	const [ selection ] = useState([]);

	const ref = firebase.db
		.collection('reviews-epa-7')
		.where('reviewStatus', '==', 'replied')
		.orderBy('createTime', 'desc')
		.limit(500);

	useEffect(() => {
		const observer = ref.onSnapshot(async (snapshot) => {
			const tableFields = snapshot.docs.map((doc) => {
				const {
					reviewId,
					locationId,
					address,
					comment,
					reviewer,
					createTime,
					reviewReply,
					starRating,
					storeCode,
					urlReview
				} = doc.data();
				return {
					reviewId,
					locationId,
					reviewer: reviewer.displayName,
					pic: reviewer.profilePhotoUrl && (
						<img src={reviewer.profilePhotoUrl} alt={reviewer.displayName} height="40" width="40" />
					),
					comment,
					createTime: moment(createTime.toDate()).format('YYYY-MM-DD h:mm a'),
					reviewReply: reviewReply.comment,
					starRating,
					storeCode,
					locality: `${address.locality}, ${address.administrativeArea}`,
					location: (
						<a href={urlReview} target="_blank" rel="noopener noreferrer">
							<i className="pi pi-external-link" />
						</a>
					)
				};
			});
			setTableData(tableFields);
			setLoading(false);
		});
		return () => observer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<div className="global-loading">
				<ProgressSpinner />
			</div>
		);
	}

	return (
		<div className="main-table replied">
			{!_.isEmpty(selection) && <Submit data={selection} />}
			<h2>Replied Reviews</h2>
			<DataTable
				value={tableData}
				sortMode="multiple"
				responsive={true}
				reorderableColumns={true}
				paginator={true}
				rows={50}
			>
				<Column field="pic" header="Profile" style={{ width: 100, padding: 5, textAlign: 'center' }} />
				<Column field="reviewer" header="Reviewer" sortable={true} />
				<Column field="comment" header="Comment" sortable={true} />
				<Column field="createTime" header="Creation time" sortable={true} style={{ width: 190 }} />
				<Column field="reviewReply" header="Review Reply" sortable={true} />
				<Column field="starRating" header="Rating" />
				<Column
					field="locality"
					header="Locality"
					filter={true}
					filterMatchMode="contains"
					tyle={{ width: '240px' }}
				/>
				<Column field="location" header="Location" style={{ width: '110px', textAlign: 'center' }} />
			</DataTable>
		</div>
	);
}
