import { gql } from "apollo-boost";

const GET_REPLY_LIST = gql`
  query GetReplyLists {
    getReplyLists {
      preset
      listMessages
      starRating
      language
    }
  }
`;

export { GET_REPLY_LIST };
