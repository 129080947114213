import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function OptionsAnswers() {
  const history = useHistory();
  return [
    {
      label: "Answers",
      icon: "pi pi-fw pi-comments",
      items: [
        {
          label: "List",
          icon: "pi pi-fw pi-list",
          command: () => history.push(ROUTES.ANSWER_LIST),
        },
        {
          label: "Add",
          icon: "pi pi-fw pi-plus-circle",
          command: () => history.push(ROUTES.ADD_REPLIES),
        },
      ],
    },
  ];
}
