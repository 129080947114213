const isProd = process.env.REACT_APP_STAGE === "production";

let url;

isProd
  ? (url = "https://us-central1-sol-naciente-gmb-automation.cloudfunctions.net")
  : (url = "http://localhost:5000/sol-naciente-gmb-automation/us-central1");

export default url;

//temp
export const ENDPOINT =
  "http://localhost:5000/gmb-reviews-37ac4/us-central1/batchReply";
